function load() {
  "use strict";


  // Toggles the "dark-mode" class based on if the media query matches
  function toggleDarkMode(state) {

    const hasClass = document.body.classList.contains("dark-mode");
    
    if (state) {
      if (!hasClass) {
        document.body.classList.add("dark-mode");
      }

    } else {
      if (hasClass) {
        document.body.classList.remove("dark-mode");
      }
    }
  }

  // MediaQueryList object
  const useDark = window.matchMedia("(prefers-color-scheme: dark)");
  let darkModeState = useDark.matches;

  // Listen for changes in the OS settings
  // addListener is used because older versions of Safari don't support addEventListener
  useDark.addListener(function(evt) {
    toggleDarkMode(evt.matches);
  });

  // Initial setting depending on the prefers-color-mode
  toggleDarkMode(darkModeState);

}

document.addEventListener("DOMContentLoaded", load);
